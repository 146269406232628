.content {
  padding: 45px 50px 30px;
}

.add {
  font-size: 13px;
  background-color: #daddde;
  line-height: 1.42857143;
  color: #58666e;
  margin: 0;
}

.panel {
  width: 30%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  width: 100%;
}

.textLeft {
  font-size: 18px;
  margin: 10px 0;
}

.textRight span {
  font-size: 25px;
}

.panelHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}


.panelBody {
  margin-bottom: 50px;
}

.content h3,
.content p {
  text-align: left;
}

.content .panelBody {
  margin-bottom: 15px;
}

.all li {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 15px 20px;
}

.all li:nth-child(odd) {
  background-color: #ebeff0;
}

.panelAdd {
  padding: 5px 10px;
  border: 1px solid #918c8c;
  border-radius: 5px;
  font-size: 13px;
}

.panelAdd:hover {
  background-color: #918c8c;
  color: white;
}

h3.panelTitle {
  margin-bottom: 20px;
}

.edit {
  cursor: pointer;
  color: green;
  margin-right: 10px;
}

.trash {
  color: red;
  cursor: pointer;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.42857143;
  color: #58666e;
  margin: 0;

  &Title {
    margin-bottom: 20px;
  }

  svg {
    line-height: 1.5;
    margin: 0 5px;
  }
}