.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;

    &__img {
      margin-top: 28px;
    }

    &__text {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 52px;
    }

  }
}

.btn {
  padding: 0;
  height: 50px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  box-shadow: 0px 4px 20px rgba(92, 62, 198, 0.4);
  border-radius: 15px;
  background-color: #007fff;

  &:active {
    position: relative;
    top: 1px;
  }
}

.textInput {
  margin-bottom: 20px;
  height: 50px;
  font-size: 16px;
}