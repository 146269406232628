.wrapper {
  padding: 45px 50px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.panel {
  padding: 20px;
  background-color: white;

  &Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h4 {
      margin: 10px 0;
      font-size: 18px;
    }
  }

  &Body {
    text-align: center;
    margin-bottom: 50px;

    p {
      font-size: 13px;
      line-height: 1.42857143;
    }
  }
}