* {
  box-sizing: border-box;
  transition: background-color 400ms;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 100%;
  background-color: #daddde;
  font-size: 24px;
  line-height: 27px;
  min-width: 320px;
  color: #000;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
}

h1 {
  font-size: 107px;
  font-weight: 300;
  line-height: 124px;
  margin: 0;
}

h3 {
  font-size: 36px;
  line-height: 41px;
  font-weight: 300;
  margin-top: 0;
}

p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}

.ql-tooltip {
  left: 0 !important
}