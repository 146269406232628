.content {
  padding: 45px 50px 30px;
  font-size: 13px;
  line-height: 1.42857143;
}

.panel {
  width: 30%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  width: 100%;

  .panelHeading {
    margin: 20px 0;
  }

  .panelTitle {
    width: 60%;
    height: 25px;
    padding-left: 15px;
  }
}

.panelBody {
  margin-bottom: 15px;
}

h3.panelTitle {
  margin-bottom: 20px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.42857143;
  color: #58666e;
  margin: 0;

  &Title {
    margin-bottom: 20px;
  }

  svg {
    line-height: 1.5;
    margin: 0 5px;
  }
}

.skills,
.requirements {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.todo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 15px auto;
}

.todo__input {
  position: relative;
  width: 100%;
}

.todo__text {
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 6px 34px 6px 10px;
  width: 100%;
}

.todo__text:focus {
  border-color: #dcedc8;
}

.todo__add {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 26px;
  width: 26px;
  background-color: #8bc34a;
  cursor: pointer;
  transition: background-color 0.15s ease-in;
}

.todo_out__add {
  position: initial;
  margin-top: 67px;
}

.todo__add:hover {
  background-color: #689f38;
}

.todo__text:disabled+.todo__add {
  cursor: default;
  background-color: #8bc34a;
  opacity: 0.5;
}

.todo__add::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' class=''%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.todo__items {
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  position: relative;
  overflow: hidden;
  font-size: 18px;
}

.todo__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
}

.todo__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.todo__action {
  position: absolute;
  top: -20px;
  right: 15px;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  transform: translateY(-50%);
  background-color: #80deea;
  opacity: 0.5;
  transition: top 0.3s ease-in;
  cursor: pointer;
}

.todo__action::before {
  content: '';
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.todo__action_delete {
  right: 52px;
  background-color: #e57373;
}

.todo__action_delete::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z'%3E%3C/path%3E%3C/svg%3E");
}

.todo__item:hover .todo__action {
  top: 50%;
}

.todo__item:hover .todo__action:hover {
  opacity: 1;
}

[data-todo-state="active"] .todo__action_restore,
[data-todo-state="deleted"] .todo__action_complete {
  display: none;
}


[data-todo-state="deleted"] .todo__task {
  color: #9e9e9e;
}

[data-todo-state="deleted"] .todo__action_restore {
  right: 15px;
}

[data-todo-option="active"] .todo__item:not([data-todo-state="active"]),
[data-todo-option="deleted"] .todo__item:not([data-todo-state="deleted"]) {
  display: none;
}

.requirements .todo__add {
  position: relative;
  transform: translateY(7%);
}

.twoRequirements {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  column-gap: 40px;
}

.requirementsRu,
.requirementsEng,
.skillsEng h4 {
  font-size: 13px;
  line-height: 1.42857143;
}

.post_textarea {
  width: 100%;
  margin: 30px 0;
}

.title {
  margin: 0;
  font-size: 24px;
}