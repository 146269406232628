.navbarAdmin {
  height: 55px;
  z-index: 99;
  background-color: #007fff;
  color: white;
  border: none;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  width: 100%;
  position: fixed;
}

.linkAdminLeftMenu {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.userNav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.userName {
  font-size: 20px;
  font-weight: 600;
}

.userNav>li {
  padding: 0 10px;
  list-style-type: none;
}

.imgAvatar {
  height: 40px;
  width: 40px;
  border: 4px solid #1866f3;
  margin-top: 5px;
  border-radius: 50%;
}

.adminLeftMenu {
  background-color: white;
  left: 0;
  padding-top: 20px;
  z-index: 222;
  width: 250px;
  height: 100%;
  position: fixed;
  overflow-y: hidden;
  margin-top: 55px;
  z-index: 99;

  a {
    color: #000;
    font-size: 18px;
    line-height: 42px;
    display: block;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;
  }

  svg {
    padding-right: 10px;
    width: auto !important;
  }

  .active {
    border-left: 4px solid #C1B8B8;
  }

  .rightArrow {
    float: right;
  }

  .subMenu {
    background-color: #F6F8F8;
    padding: 0px;
    margin: 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    position: absolute;
    z-index: 9999999;
    min-width: 250px;
    visibility: hidden;
    opacity: 0;
    transform-origin: 0% 0%;
    transform: rotateX(-90deg);
    transition: .3s linear;
  }
}

.ripple {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ripple:hover>.subMenu {
  transform: rotateX(0deg);
  visibility: visible;
  opacity: 1;
}